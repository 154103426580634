<template>
  <fw-layout :full="isFullLayout" :back-to="backToPath" :loading="initialLoading" loading-title="Torneio">
    <template #header-nav>
      <fw-heading size="lg">
        <v-clamp :max-lines="1" autoresize class="font-bold">{{ tournament.title[language] }}</v-clamp>
      </fw-heading>
    </template>

    <template v-if="hasAvailableActions" #header-toolbar>
      <BlockHeaderActions
        class="mx-5"
        :items="callActions"
        @publish="confirmPublish"
        @open-signup="confirmOpenSignup(true)"
        @close-signup="confirmOpenSignup(false)"
      />
    </template>

    <template v-if="view !== 'team'" #main-sidebar>
      <SidebarManageTournament :has-only-final-stage="hasOnlyFinalStage" />
    </template>

    <template #main-content>
      <PanelManageTournamentDashboard
        v-if="view == 'dashboard' && tournament && tournament.key"
        :tournament="tournament"
        :league="league"
        :stats="tournament.stats"
        :users="users"
        :sport="tournamentSport"
        :validations="validations"
        :loading="loading"
      />
      <PanelManageTeams
        v-if="['teams', 'dashboard'].includes(view)"
        :tournament-key="tournament.key"
        :edition-key="editionKey"
        :league-key="leagueKey"
        :users="users"
        :results="teams"
        :loading="loading"
        :validations="validations"
        :is-academic="league?.type == 'academic'"
        @search="getTeams"
        @create-team="createTeam"
      />
      <PanelManageTournamentStages
        v-else-if="isStageView"
        :tournament="tournament"
        :league="league"
        :loading="loading"
        :view="view"
        :sport="tournamentSport"
        :saving-tournament="savingData"
        :can-edit="validations.can_edit"
        @save-tournament="updateTournament"
      />
      <PanelManageTournamentSettings
        v-else-if="view == 'settings'"
        :tournament="tournament"
        :league="league"
        :loading="loading"
        :can-edit="validations.can_edit"
        :saving-data="savingData"
        @save-tournament="updateTournament"
      />
      <PanelManageTournamentActivity
        v-else-if="view == 'activity'"
        :tournament="tournament"
        :tournament-key="tournamentKey"
      />
      <PanelManageTournamentNotifications v-else-if="view == 'notifications'" :tournament-key="tournamentKey" />
    </template>

    <template #modals>
      <fw-modal
        v-if="modalCreateTeam"
        :active.sync="modalCreateTeam"
        :can-cancel="true"
        :size="'3xl'"
        @close="closeModal"
      >
        <template #default>
          <ModalCreateTeam
            v-if="modalCreateTeam"
            :edition-key="editionKey"
            :tournament="tournament"
            :league-key="leagueKey"
            @close="closeModal"
            @saved="teamSaved()"
          ></ModalCreateTeam>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import BlockHeaderActions from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderActions'
import PanelManageTournamentDashboard from '@/components/panels/manage/PanelManageTournamentDashboard'
import PanelManageTournamentSettings from '@/components/panels/manage/PanelManageTournamentSettings'
import PanelManageTournamentStages from '@/components/panels/manage/PanelManageTournamentStages'
import PanelManageTeams from '@/components/panels/manage/PanelManageTeams'
import PanelManageTournamentActivity from '@/components/panels/manage/PanelManageTournamentActivity'
import PanelManageTournamentNotifications from '@/components/panels/manage/PanelManageTournamentNotifications'
import SidebarManageTournament from '@/components/sidebars/manage/SidebarManageTournament'
import ModalCreateTeam from '@/components/modals/ModalCreateTeam'

import { TOURNAMENT_STATUS_TAG_COLORS, TOURNAMENT_STAGES, hasOnlyFinalStage, GROUPED_LEAGUES } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    PanelManageTournamentDashboard,
    //PanelManageTournamentPeople,
    PanelManageTournamentActivity,
    PanelManageTournamentNotifications,
    PanelManageTournamentSettings,
    PanelManageTournamentStages,
    PanelManageTeams,
    BlockHeaderActions,
    SidebarManageTournament,
    ModalCreateTeam,
  },

  data() {
    return {
      tournament: {},
      sport: {},
      sports: {},
      teamUsers: {},
      users: {},
      teams: {},
      team: {},
      validations: {
        can_edit: false,
      },
      teamValidations: {},
      loading: true,
      initialLoading: true,
      savingData: false,
      tournamentStatusTagType: TOURNAMENT_STATUS_TAG_COLORS,
      managers: [],
      workers: [],
      searchQuery: null,
      stagesLabels: TOURNAMENT_STAGES,
      defaultAdditionalData: {
        tax_tier: null,
        accommodation_type: null,
        ies_code: null,
        ies: null,
        course: null,
        course_code: null,
        course_type: null,
      },
      modalIsActive: false,
      modalCreateTeam: false,
      edition: null,
      league: null,
    }
  },

  computed: {
    currentApplicationsPage() {
      return this.searchQuery?.page ?? 1
    },

    isFullLayout() {
      return this.view && this.view === 'team'
    },

    isStageView() {
      return Object.keys(this.stagesLabels).includes(this.view)
    },

    backToPath() {
      if (this.tournamentKey && this.leagueKey && this.editionKey)
        return `/manage/edition/${this.editionKey}/league/${this.leagueKey}/tournaments`
      return '/manage'
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    tournamentKey() {
      return this.$route.params.tournamentKey
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    leagueKey() {
      return this.$route.params.leagueKey
    },

    teamKey() {
      return this.$route.params.teamKey
    },

    view() {
      return this.$route.params.view ?? 'dashboard'
    },

    callActions() {
      return [
        {
          canShowAction: this.canPusblish,
          actionName: 'publish',
          actionLabel: 'Publicar torneio',
          addToCounter: true,
        },
        {
          canShowAction: this.canOpenSignup,
          actionName: 'open-signup',
          actionLabel: 'Abrir inscrições',
          addToCounter: true,
        },
        {
          canShowAction: this.canCloseSignup,
          actionName: 'close-signup',
          actionLabel: 'Fechar inscrições',
          addToCounter: false,
        },
      ]
    },

    canChangeStatus() {
      if (this.league?.type === 'group') return false
      if (this.edition?.group_league_active) return !GROUPED_LEAGUES.includes(this.league.type)

      return true
    },

    canPusblish() {
      return !this.tournament.is_visible && this.canChangeStatus
    },

    canOpenSignup() {
      return (
        !this.tournament.archived_at &&
        this.tournament.is_visible &&
        !this.tournament.is_signup_open &&
        this.canChangeStatus
      )
    },

    canCloseSignup() {
      return (
        !this.tournament.archived_at &&
        this.tournament.is_visible &&
        this.tournament.is_signup_open &&
        this.canChangeStatus
      )
    },

    hasAvailableActions() {
      return this.canPusblish || this.canOpenSignup || this.canCloseSignup
    },

    tournamentSport() {
      return this.sport ?? this.sports?.[this.tournament.sport_key]
    },

    hasOnlyFinalStage() {
      return hasOnlyFinalStage(this.league, this.tournamentSport)
    },
  },

  watch: {
    view(newVal) {
      if (newVal) this.getCurrentViewData()
    },
    teamKey(key) {
      if (key) this.getTeam()
    },
  },

  mounted() {
    this.getCurrentViewData(true)
  },

  methods: {
    teamSaved() {
      //refresh teams list
      this.getTeams()
      this.closeModal()
    },

    createTeam() {
      this.modalCreateTeam = true
    },

    selectUserSearch(selection) {
      console.log('selection', selection)
    },

    closeModal() {
      this.modalCreateTeam = false
    },

    async getCurrentViewData(initialLoad) {
      if (this.view == 'people') {
        // await this.getManagers()
      } else if (this.view == 'team') {
        await this.getTeam()
        if (this.teams?.teams || !this.teams?.teams?.length) {
          await this.getTeams()
        }
      }

      if (initialLoad || !this.view || ['dashboard', 'metadata'].includes(this.view)) {
        await this.getTournament(initialLoad)
        //await this.getManagers()
      }
    },

    async getTournament(initialLoad) {
      this.loading = true
      try {
        const response = await this.api.managementGetTournament(this.tournamentKey)
        console.log('managementGetTournament :>> ', response)
        this.sport = response.sport
        this.tournament = response.tournament
        this.edition = response.edition
        this.league = response.league
        this.validations = response.tournament.validations

        // Set last call opened
        this.saveLocalLastTournament()
      } catch (error) {
        console.log('Error getTournament :>> ', error)
      } finally {
        // Run just once
        if (initialLoad) {
          setTimeout(() => {
            this.initialLoading = false
          }, 750)
        }
      }
      this.loading = false
    },

    pageChanged(page) {
      //Sidebar page change with the same query as the main search
      if (this.searchQuery == null) {
        this.searchQuery = {}
      }
      this.searchQuery.page = page
      this.getTeams(this.searchQuery)
    },

    async getTeams(query = null) {
      //save search query to be used in sidebar pageChanged
      this.searchQuery = query
      console.log('getTeams with query :>> ', query)
      this.loading = true

      try {
        const response = await this.api.managementGetTournamentTeams(this.tournamentKey, query)
        console.log('getTeams :>> ', response)
        //this.tournament = response.tournament
        this.teams = response
        this.users = response.users
        this.teamUsers = response.users
        if (response.validations) this.validations = response.validations
        this.sports = response.sports
        console.log('this.sport  :>> ', this.sport)
      } catch (error) {
        console.log('Error getTournaments :>> ', error)
        this.handleErrors(error, 'Torneio não encontrado.')
      }

      this.loading = false
    },

    async getTeam() {
      this.loading = true

      try {
        const response = await this.api.managementGetTeamDetails(this.tournamentKey, this.teamKey)
        console.log('getTeamMANAGEMENT :>> ', response)
        this.team = response.team
        this.tournament = response.tournament
        this.teamValidations = response.validations

        // Fix some extra metadata
        this.team.rejected_reasons = this.team.rejected_reasons ?? []

        console.log('Team additional data from API >>', response.team.additional_data)
        console.log('Team additional data from DOM >>', this.team.additional_data)

        // Fix additional data
        if (!response.team.additional_data) {
          this.team.additional_data = { ...this.defaultAdditionalData }
        }

        console.log('getTeam :>>', response)
      } catch (error) {
        console.log('Error getTeam :>> ', error)
      }

      this.loading = false
    },

    async updateTournament(payload, successText = 'Metadados atualizados com sucesso.') {
      console.log('updateTournament with payload :>> ', payload)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.managementUpdateTournament(this.tournamentKey, payload)
          console.log('updateTournament :>> ', response)
          this.tournament = response
          this.validations = response.validations

          this.$buefy.snackbar.open({
            message: successText,
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    async updateSignupTournament(signupOpen) {
      console.log('updateSignupTournament with signupOpen :>> ', signupOpen)
      this.savingData = true

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.managementUpdateSignupTournament(this.tournamentKey, signupOpen)
          console.log('updateTournament :>> ', response)
          this.tournament = response
          this.validations = response.validations

          this.$buefy.snackbar.open({
            message: `Inscrições ${signupOpen ? 'abertas' : 'fechadas'} com sucesso.`,
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
        },
        () => {
          this.savingData = false
        }
      )
    },

    confirmPublish() {
      if (this.validations.is_visible) return

      this.$buefy.dialog.confirm({
        title: `Publicar torneio`,
        cancelText: 'Cancelar',
        confirmText: 'Publicar',
        message: `Tem a certeza que deseja <strong>Publicar</strong> este torneio?`,
        onConfirm: () => {
          this.updateTournament({ is_visible: true }, 'Torneio publicado com sucesso.')
        },
      })
    },

    confirmOpenSignup(signupOpen = true) {
      this.$buefy.dialog.confirm({
        title: `${signupOpen ? 'Abrir' : 'Fechar'} inscrições no torneio`,
        cancelText: 'Cancelar',
        confirmText: `${signupOpen ? 'Abrir' : 'Fechar'}`,
        message: `Tem a certeza que deseja <strong>${
          signupOpen ? 'Abrir' : 'Fechar'
        } inscrições</strong> para este torneio?`,
        type: signupOpen ? 'is-primary' : 'is-danger',
        onConfirm: () => {
          this.updateSignupTournament(signupOpen)
        },
      })
    },

    // Utils
    saveLocalLastTournament() {
      const tournament = {
        title: this.tournament.title[this.language],
        key: this.tournament.key,
      }
      localStorage.setItem('last-tournament-open', JSON.stringify(tournament))
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "callCodeAlreadyExists": {
        "title": "Código já existe",
        "message": "Já existe um procedimento com o código que tentou definir. Por favor, indique outro para guardar as alterações."
      },
      "status": "Estado",
      "publish": "Publicar",
      "delete": "Eliminar",
      "callStatus": {
        "draft": "Rascunho",
        "published": "Publicado",
        "deleted": "Removido"
      }
    },
    "en": {
      "callCodeAlreadyExists": {
        "title": "Code already exists",
        "message": "There is already a row with the code you tried to set. Please enter another one to save your changes."
      },
      "publish": "Publish",
      "delete": "Delete",
      "status": "Status",
      "callStatus": {
        "draft": "Draft",
        "published": "Published",
        "deleted": "Deleted"
      }
    }
  }
  </i18n>
