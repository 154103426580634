<template>
  <div v-if="tmpStage">
    <div class="mt-4">
      <fw-label>Tipo</fw-label>
      <b-select v-model="tmpStage.type" placeholder="Tipo" expanded>
        <option v-for="(option, key) in typesList" :key="key" :value="key">
          {{ option[language] }}
        </option>
      </b-select>

      <fw-tip v-if="$v.tmpStage.type.$error" error> Insira a edição dos novos torneios</fw-tip>
    </div>

    <div v-if="tmpStage.type == 'swiss'" class="mt-4">
      <div class="mb-2">
        <fw-label>Rondas</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.swiss_options.rounds"
            :placeholder="'Pontos por jogo/set ganho'"
            :max="999"
            :min="1"
          ></NumberInput>
        </div>
        <fw-tip v-if="$v.tmpStage.swiss_options.rounds.$error" error>
          <span v-if="!$v.tmpStage.swiss_options.rounds.min">
            Insira um número de rondas superior a 1
          </span>
          <span v-else-if="!$v.tmpStage.swiss_options.rounds.max">
            Insira um número de rondas inferior a 999
          </span>
          <span v-else>Insira o número de rondas</span>
        </fw-tip>
      </div>
      <div class="mb-2">
        <fw-label>Pontos por jogo/set ganho</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.swiss_options.pts_for_game_win"
            :placeholder="'Pontos por jogo/set ganho'"
            :max="999"
          ></NumberInput>
        </div>
        <fw-tip v-if="$v.tmpStage.swiss_options.pts_for_game_win.$error" error>
          <span v-if="!$v.tmpStage.swiss_options.pts_for_game_win.max">
            Insira um número inferior a 999
          </span>
          <span v-else>Insira o número de Pontos por jogo/set ganho</span>
        </fw-tip>
      </div>
      <div class="mb-2">
        <fw-label>Pontos por jogo/set empate</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.swiss_options.pts_for_game_tie"
            :placeholder="'Pontos por jogo/set empate'"
            :max="999"
          ></NumberInput>
        </div>

        <fw-tip v-if="$v.tmpStage.swiss_options.pts_for_game_tie.$error" error>
          <span v-if="!$v.tmpStage.swiss_options.pts_for_game_tie.max">
            Insira um número inferior a 999
          </span>
          <span v-else>Insira o número de Pontos por jogo/set empate</span>
        </fw-tip>
      </div>
      <div class="mb-2">
        <fw-label>Pontos por partida ganha</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.swiss_options.pts_for_match_win"
            :placeholder="'Pontos por partida ganha'"
            :max="999"
          ></NumberInput>
        </div>

        <fw-tip v-if="$v.tmpStage.swiss_options.pts_for_match_win.$error" error>
          <span v-if="!$v.tmpStage.swiss_options.pts_for_match_win.max">
            Insira um número inferior a 999
          </span>
          <span v-else>Insira o número de pontos por partida ganha</span>
        </fw-tip>
      </div>
      <div class="mb-2">
        <fw-label>Pontos por partida empate</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.swiss_options.pts_for_match_tie"
            :placeholder="'Pontos por partida empate'"
            :max="999"
          ></NumberInput>
        </div>

        <fw-tip v-if="$v.tmpStage.swiss_options.pts_for_match_tie.$error" error>
          <span v-if="!$v.tmpStage.swiss_options.pts_for_match_tie.max">
            Insira um número inferior a 999
          </span>
          <span v-else>Insira o número de pontos por partida por empate</span>
        </fw-tip>
      </div>
    </div>

    <div v-if="tmpStage.type == 'round_robin'" class="mt-4">
      <div class="mb-2">
        <fw-label>Classificar por</fw-label>
        <b-select v-model="tmpStage.round_robin_options.ranking" expanded>
          <option v-for="ranking in roundRobinRankingOptions" :key="ranking.value" :value="ranking.value">{{
            ranking.label
          }}</option>
        </b-select>

        <fw-tip v-if="$v.tmpStage.round_robin_options.ranking.$error" error>
          Escolha uma opção
        </fw-tip>
      </div>

      <div class="mb-2">
        <fw-label>Rondas</fw-label>
        <div class="flex flex-col gap-3">
          <NumberInput
            v-model="tmpStage.round_robin_options.iterations"
            :placeholder="'Pontos por jogo/set ganho'"
            :max="999"
            :min="1"
          ></NumberInput>
        </div>
        <fw-tip v-if="$v.tmpStage.round_robin_options.iterations.$error" error>
          <span v-if="!$v.tmpStage.round_robin_options.iterations.min">
            Insira um número superior a 0
          </span>
          <span v-else-if="!$v.tmpStage.round_robin_options.iterations.max">
            Insira um número inferior a 999
          </span>
          <span v-else>Insira o número rondas</span>
        </fw-tip>
      </div>

      <template v-if="tmpStage.round_robin_options.ranking == 'custom'">
        <div class="mb-2">
          <fw-label>Pontos por jogo/set ganho</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="tmpStage.round_robin_options.pts_for_game_win"
              :placeholder="'Pontos por jogo/set ganho'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tmpStage.round_robin_options.pts_for_game_win.$error" error>
            <span v-if="!$v.tmpStage.round_robin_options.pts_for_game_win.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de Pontos por jogo/set ganho</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por jogo/set empate</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="tmpStage.round_robin_options.pts_for_game_tie"
              :placeholder="'Pontos por jogo/set empate'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tmpStage.round_robin_options.pts_for_game_tie.$error" error>
            <span v-if="!$v.tmpStage.round_robin_options.pts_for_game_tie.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de Pontos por jogo/set empate</span>
          </fw-tip>
        </div>
        <div class="mb-2">
          <fw-label>Pontos por partida ganha</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="tmpStage.round_robin_options.pts_for_match_win"
              :placeholder="'Pontos por partida ganha'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tmpStage.round_robin_options.pts_for_match_win.$error" error>
            <span v-if="!$v.tmpStage.round_robin_options.pts_for_match_win.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de pontos por partida ganha</span>
          </fw-tip>
        </div>
        <div>
          <fw-label>Pontos por partida por empate</fw-label>
          <div class="flex flex-col gap-3">
            <NumberInput
              v-model="tmpStage.round_robin_options.pts_for_match_tie"
              :placeholder="'Pontos por partida por empate'"
              :max="999"
            ></NumberInput>
          </div>

          <fw-tip v-if="$v.tmpStage.round_robin_options.pts_for_match_tie.$error" error>
            <span v-if="!$v.tmpStage.round_robin_options.pts_for_match_tie.max">
              Insira um número inferior a 999
            </span>
            <span v-else>Insira o número de pontos por partida empate</span>
          </fw-tip>
        </div>
      </template>
    </div>

    <div class="mt-4">
      <fw-label>Número máximo de equipas por torneio</fw-label>
      <div class="flex flex-col gap-3">
        <NumberInput v-model="tmpStage.max_teams" :placeholder="'Número de equipas'" :maxlength="20"></NumberInput>
      </div>
      <fw-tip v-if="$v.tmpStage.max_teams.$error" error> Insira o número máximo de equipas</fw-tip>
    </div>

    <fw-panel-info debug label="Data (raw)" class="my-4">
      <json-viewer :value="{ tmpStage, v: $v }"></json-viewer>
    </fw-panel-info>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="$emit('close')">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disabled="saving" :loadind="saving" class="w-28" @click.native="saveStage">
        {{ 'Guardar' }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES, ROUND_ROBIN_RANKING_OPTIONS } from '@/utils/index.js'
import { required, numeric, maxValue, minValue, minLength, requiredIf } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'

export default {
  components: {
    NumberInput,
  },

  props: {
    stage: {
      type: Object,
      default: () => {},
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    saving: {
      type: Boolean,
      default: false,
    },

    hasOnlyFinalStage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      minSignUpDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      minDate: Dates.now()
        .add(1, 'day')
        .toDate(),
      stages: TOURNAMENT_STAGES,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
      roundRobinRankingOptions: ROUND_ROBIN_RANKING_OPTIONS,
      tmpStage: null,
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
  },

  watch: {
    'tmpStage.type'(newVal) {
      if (newVal == 'swiss' && !this.tmpStage.swiss_options) {
        this.tmpStage.swiss_options = {
          ranking: 'points_scored',
          rounds: 1,
          pts_for_game_win: 1,
          pts_for_game_tie: 0,
          pts_for_match_win: 1,
          pts_for_match_tie: 0,
        }
      }
      if (newVal == 'round_robin' && !this.tmpStage.round_robin_options) {
        this.tmpStage.round_robin_options = {
          iterations: 1,
          pts_for_game_win: 1,
          pts_for_game_tie: 0,
          pts_for_match_win: 1,
          pts_for_match_tie: 0,
        }
      }
    },
  },

  mounted() {
    this.tmpStage = JSON.parse(JSON.stringify(this.stage))
    if (this.stage.type == 'swiss' && !this.stage.swiss_options) {
      this.tmpStage.swiss_options = {
        ranking: 'points_scored',
        rounds: 1,
        pts_for_game_win: 1,
        pts_for_game_tie: 0,
        pts_for_match_win: 1,
        pts_for_match_tie: 0,
      }
    }
    if (this.stage.type == 'round_robin' && !this.stage.round_robin_options) {
      this.tmpStage.round_robin_options = {
        iterations: 1,
        pts_for_game_win: 1,
        pts_for_game_tie: 0,
        pts_for_match_win: 1,
        pts_for_match_tie: 0,
      }
    }
    console.log('this.tmpStage :>> ', this.tmpStage)
  },

  validations() {
    return {
      tmpStage: {
        stage: { required, min: minLength(1) },
        type: { required, min: minLength(1) },
        max_teams: { numeric },
        swiss_options: {
          required: requiredIf(nestedModal => {
            return nestedModal.type == 'swiss'
          }),
          rounds: {
            numeric,
            min: minValue(1),
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'swiss'
            }),
          },
          pts_for_game_win: {
            numeric,
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'swiss'
            }),
          },
          pts_for_game_tie: {
            numeric,
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'swiss'
            }),
          },
          pts_for_match_win: {
            numeric,
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'swiss'
            }),
          },
          pts_for_match_tie: {
            numeric,
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'swiss'
            }),
          },
        },
        round_robin_options: {
          required: requiredIf(nestedModal => {
            return nestedModal.type == 'round_robin'
          }),
          iterations: {
            numeric,
            min: minValue(1),
            max: maxValue(999),
            required: requiredIf(() => {
              return this.tmpStage.type == 'round_robin'
            }),
          },
          ranking: {
            required: requiredIf(() => {
              return this.tmpStage.type == 'round_robin'
            }),
          },
          pts_for_game_win: {
            numeric,
            max: maxValue(999),
            required: requiredIf(nestedModal => {
              return this.tmpStage.type == 'round_robin' && nestedModal.ranking == 'custom'
            }),
          },
          pts_for_game_tie: {
            numeric,
            max: maxValue(999),
            required: requiredIf(nestedModal => {
              return this.tmpStage.type == 'round_robin' && nestedModal.ranking == 'custom'
            }),
          },
          pts_for_match_win: {
            numeric,
            max: maxValue(999),
            required: requiredIf(nestedModal => {
              return this.tmpStage.type == 'round_robin' && nestedModal.ranking == 'custom'
            }),
          },
          pts_for_match_tie: {
            numeric,
            max: maxValue(999),
            required: requiredIf(nestedModal => {
              return this.tmpStage.type == 'round_robin' && nestedModal.ranking == 'custom'
            }),
          },
        },
      },
    }
  },

  methods: {
    saveStage() {
      this.$v.$touch()
      if (this.$v.tmpStage.$invalid) return

      this.$emit('save', this.tmpStage)
      console.log('save me :>> ', this.tmpStage)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notDefined": "Não definido"
  },
  "en": {
    "notDefined": "Not defined"
  }
}
</i18n>
