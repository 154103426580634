<template>
  <div>
    <fw-panel :title="$t('stages')" featured :loading="savingTournament" after-loading-checked> </fw-panel>

    <LoadingPlaceholder v-if="loading || loadingData" />

    <template v-else>
      <div v-if="currentViewStages && view">
        <fw-panel :title="stages[view][language]" boxed="lg" class="my-5" custom-class="bg-white flex flex-col gap-1">
          <template #toolbar>
            <fw-button
              v-if="canEdit && !isOnlyTeamGroupManager && !hasOnlyFinalStage"
              type="link"
              @click.native="openMoveTeamsModal"
              >Mover equipas</fw-button
            >
          </template>

          <RecordManageStage
            v-for="stage in currentViewStages"
            :key="stage.key"
            :stage="stage"
            :team-group="teamGroups[stage.team_group]"
            :organic-unit="
              teamGroups[stage.team_group]
                ? organicUnits[teamGroups[stage.team_group].organic_unit]
                : organicUnits[stage.organic_unit]
            "
            @edit="openStageEditModal"
            @start="confirmStartStage(stage.key)"
            @end="confirmEndStage(stage)"
            @create-chat="createChat(stage)"
          ></RecordManageStage>
        </fw-panel>
      </div>

      <fw-panel-info v-else-if="!currentViewStages.length" type="basic">Sem fases definidas</fw-panel-info>
    </template>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ view, currentViewStages, teamGroups, tournament, confirmStart }"></json-viewer>
    </fw-panel-info>

    <fw-modal :active.sync="isModalActive" boxed="sm" size="3xl" width="42rem" @close="closeModal">
      <ModalEditStage
        v-if="isModalActive && activeModalStage"
        :has-only-final-stage="hasOnlyFinalStage"
        :stage="activeModalStage"
        :saving="savingTournament"
        :can-edit="canEdit"
        @close="closeModal"
        @save="saveTournamentStage"
      ></ModalEditStage>
      <ModalMoveTeams
        v-if="isModalActive && activeModalMoveTeams"
        :tournament-key="tournament.key"
        :stages="tournamentStages"
        :default-from-stage="view"
        :league-key="league.key"
        @close="closeModal"
        @moved="getData"
      >
      </ModalMoveTeams>
    </fw-modal>

    <!-- Imitates the confirm dialog -->
    <b-modal
      v-model="isModalConfirmStartActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal
      custom-class="dialog modal"
      @close="isModalConfirmStartActive = false"
    >
      <template v-if="isModalConfirmStartActive">
        <div class=" modal-card animation-content w-auto">
          <header class="modal-card-head bg-white border-b-0">
            <p class="modal-card-title font-bold">Iniciar fase</p>
          </header>
          <section class="modal-card-body">
            <div class="media">
              <div class="media-content">
                <p>Tem a certeza que deseja <b>iniciar</b> esta fase?</p>
                <b-field v-if="confirmStart.showCreateByes">
                  <b-checkbox v-model="confirmStart.createByes">Criar byes</b-checkbox>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot bg-white border-t-0">
            <button type="button" class="button" @click="isModalConfirmStartActive = false">
              <span>Cancelar</span>
            </button>

            <fw-button type="primary" :loading="saving" @click.native="startStage(true)">Iniciar</fw-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="isModalConfirmEndActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal
      custom-class="dialog modal"
      @close="isModalConfirmEndActive = false"
    >
      <template v-if="isModalConfirmEndActive">
        <div class=" modal-card animation-content w-auto">
          <header class="modal-card-head bg-white border-b-0">
            <p class="modal-card-title font-bold">Terminar fase</p>
          </header>
          <section class="modal-card-body">
            <div class="media">
              <div class="media-content">
                <p>Tem a certeza que deseja <b>terminar</b> esta fase?</p>
                <b-field v-if="confirmEnd.stage.stage != 'final'">
                  <b-checkbox v-model="confirmEnd.move">Mover equipa vencedora para a fase seguinte</b-checkbox>
                </b-field>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot bg-white border-t-0">
            <button type="button" class="button" @click="isModalConfirmEndActive = false">
              <span>Cancelar</span>
            </button>

            <fw-button type="primary" :loading="saving" @click.native="endStage()">Terminar</fw-button>
          </footer>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
// import NumberInput from '@/fw-modules/fw-core-vue/ui/components/form/NumberInput'
import RecordManageStage from '@/components/records/manage/RecordManageStage'
import ModalEditStage from '@/components/modals/ModalEditStage'
import ModalMoveTeams from '@/components/modals/ModalMoveTeams'
// import { required, minLength, requiredIf, helpers, minValue } from 'vuelidate/lib/validators'
// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { GENDERS, TOURNAMENT_TYPES, TOURNAMENT_STAGES, hasOnlyFinalStage } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder,
    RecordManageStage,
    ModalEditStage,
    ModalMoveTeams,
  },

  props: {
    tournament: {
      type: Object,
      default: () => {
        return {
          genders: [],
          title: {
            pt: '',
            en: '',
          },
          description: {
            pt: '',
            en: '',
          },
          sport_key: null,
          stages: [],
        }
      },
    },

    league: {
      type: Object,
      default: () => {},
    },

    sport: {
      type: Object,
      default: () => {},
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    savingTournament: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    view: {
      type: String,
      default: 'final',
    },
  },

  data() {
    return {
      saving: false,
      teamGroups: {},
      organicUnits: {},
      tournamentStages: {},
      editionActive: false,
      stages: TOURNAMENT_STAGES,
      gendersList: GENDERS,
      typesList: TOURNAMENT_TYPES,
      isModalActive: false,
      activeModalMoveTeams: null,
      activeModalStage: null,
      loadingData: false,
      isModalConfirmStartActive: false,
      confirmStart: {
        stageKey: null,
        showCreateByes: false,
        createByes: false,
      },
      isModalConfirmEndActive: false,
      confirmEnd: {
        move: false,
        stage: null,
      },
    }
  },

  computed: {
    editable() {
      return this.canEdit && this.editionActive
    },

    api() {
      return this.$store.state.api.base
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },

    langs() {
      return [this.language, this.secondaryLanguage]
    },

    hasOnlyFinalStage() {
      return hasOnlyFinalStage(this.league, this.sport)
    },

    currentViewStages() {
      return (this.tournamentStages[this.view] ?? []).sort((a, b) => {
        const aGroup = this.teamGroups?.[a.team_group]
        const bGroup = this.teamGroups?.[b.team_group]
        const aOrgUnit = this.organicUnits?.[a.organic_unit ?? aGroup.organic_unit]
        const bOrgUnit = this.organicUnits?.[b.organic_unit ?? bGroup.organic_unit]
        return `${aOrgUnit?.name}${aGroup?.name}`.localeCompare(`${bOrgUnit?.name}${bGroup?.name}`)
      })
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    isOnlyTeamGroupManager() {
      return (
        this.userPermissions.isTeamGroupManager &&
        !this.userPermissions.isGlobalManager &&
        !this.userPermissions.isLeagueManager
      )
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    powerOfTwo(x) {
      return Math.log2(x) % 1 === 0
    },

    closeModal() {
      this.isModalActive = false
      this.activeModalStage = null
      this.activeModalMoveTeams = false
    },

    openStageEditModal(stage) {
      this.activeModalStage = stage
      this.isModalActive = true
    },

    openMoveTeamsModal() {
      this.activeModalMoveTeams = true
      this.isModalActive = true
    },

    async saveTournamentStage(stageData) {
      console.log('stageData :>> ', stageData)
      const payload = Object.assign({}, stageData)

      await utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.managementEditTournamentStage(this.tournament.key, stageData.key, payload)
          console.log('managementEditTournamentStage :>> ', response)
          this.$buefy.snackbar.open({
            message: 'Fase editada com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
          const stage = this.tournamentStages[this.view].find(s => s.key === stageData.key)
          if (stage) {
            stage.max_teams = response.max_teams
            stage.type = response.type
            stage.swiss_options = response.swiss_options
            stage.round_robin_options = response.round_robin_options
          }
        },
        () => {
          this.saving = false
        }
      )
      this.closeModal()
    },

    async confirmStartStage(stageKey) {
      this.confirmStart = {
        stageKey,
        showCreateByes: false,
        createByes: false,
      }

      if (await this.startStage(false)) this.isModalConfirmStartActive = true
    },

    async startStage(confirm = true) {
      if (confirm) this.saving = true

      try {
        const response = await this.api.managementStartTournament(this.tournament.key, this.confirmStart.stageKey, {
          create_byes: this.confirmStart.createByes,
          confirm: confirm,
        })

        console.log('managementStartTournament :>> ', response)

        this.confirmStart.showCreateByes = response.byes_required > 0
        if (confirm) {
          this.isModalConfirmStartActive = false
          this.$buefy.snackbar.open({
            message: 'Fase iniciada com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
          const stage = this.tournamentStages[this.view].find(s => s.key === this.confirmStart.stageKey)
          if (stage) {
            if (response.challonge) stage.challonge = response.challonge
            stage.started_at = response.started_at
            if (response.validations) stage.validations = response.validations
          }
        }

        this.saving = false
        return true
      } catch (err) {
        const errorKey = utils.errors(err).getKey()

        if (errorKey == 'NotEnoughTeams') {
          this.$buefy.snackbar.open({
            message: 'Não existem equipas suficientes para iniciar o torneio.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true,
          })
        } else {
          utils.errorDialogAlert(this, err)
        }
        return false
      }
    },

    confirmEndStage(stage) {
      this.confirmEnd = {
        move: stage.stage != 'final',
        stage: stage,
      }
      this.isModalConfirmEndActive = true
    },

    async createChat(stage) {
      await utils.tryAndCatch(this, async () => {
        this.saving = true
        const response = await this.api.managementCreateStageChat(this.tournament.key, stage.key)
        console.log('response', response)

        this.$buefy.snackbar.open({
          message: 'Chat criado com sucesso.',
          type: 'is-primary',
          position: 'is-bottom-right',
          duration: 5000,
        })

        const existingStage = this.tournamentStages[this.view].find(s => s.key === stage.key)
        if (existingStage) {
          existingStage.chat_key = response.chat_key
        }
      })
      this.saving = false
    },

    async moveTeam(teamKey, toStage) {
      try {
        await this.api.managementUpdateTeamStage(teamKey, toStage)
        this.$buefy.toast.open({
          message: 'Equipa atualizada com sucesso.',
          type: 'is-success',
          position: 'is-top',
        })
      } catch (err) {
        console.error(err)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao mover a equipa vencedora.',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
        })
      }
    },

    async endStage() {
      await utils.tryAndCatch(
        this,
        async () => {
          this.saving = true
          const response = await this.api.managementEndTournament(this.tournament.key, this.confirmEnd.stage.key, {
            confirm: true,
          })
          console.log('response', response)

          this.$buefy.snackbar.open({
            message: 'Fase terminada com sucesso.',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })

          if (this.confirmEnd.move && response.winner_team) {
            let newStageKey = undefined
            if (this.confirmEnd.stage.stage == 'intergroups') {
              newStageKey = this.tournamentStages.final[0]?.key
            }

            if (this.confirmEnd.stage.stage == 'intragroups') {
              const teamGroup = this.teamGroups[this.confirmEnd.stage.team_group]
              newStageKey = this.tournamentStages.intergroups.find(s => s.organic_unit === teamGroup.organic_unit)?.key
            }

            if (newStageKey) this.moveTeam(response.winner_team.key, newStageKey)
          }

          const stage = this.tournamentStages[this.view].find(s => s.key === this.confirmEnd.stage.key)
          if (stage) {
            stage.ended_at = response.ended_at
            stage.winner_team = response.winner_team
            if (response.validations) stage.validations = response.validations
          }
        },
        () => {
          this.isModalConfirmEndActive = false
          this.saving = false
        }
      )
    },

    async getData() {
      this.loadingData = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.managementGetTournamentStages(this.tournament.key)
          console.log('getTournamentStages :>> ', response)
          this.tournamentStages = response.stages
          this.teamGroups = response.team_groups
          this.organicUnits = response.organic_units
        },
        () => {
          this.loadingData = false
        }
      )
    },

    toggleEditMode() {
      this.editionActive = !this.editionActive
    },
  },
}
</script>
<i18n>
{
  "pt": {
    "stages": "Fases",
    "thereAreErrors": "Existem erros no formulário",
    "close": "Fechar",
    "edit": "Editar",
    "save": "Guardar",
    "delete": "Remover",
    "generalDetails": "Detalhes gerais",
    "tournamentTitle": "Título",
    "date": {
      "label": "Data",
      "placeholder": "Escolha uma data"
    },
    "startDate": {
      "label": "Data de início",
      "min": "Escolha uma data mais recente",
      "required": "Insira uma data de início"
    },
    "endDate": {
      "label": "Data de fim",
      "min": "Escolha uma data a seguir a data de início",
      "required": "Insira uma data de fim"
    },
    "title": {
      "label": "Título",
      "pt": "Título em Português",
      "en": "Título em Inglês",
      "required": "Insira um título em Portugês e Inglês."
    },
    "description": {
      "label": "Descrição",
      "pt": "Descrição em Português",
      "en": "Descrição em Inglês",
      "required": "Insira uma descrição em Portugês e Inglês."
    },
    "charsLimit": "Limite de {limit} caracteres.",
    "notDefined": "Não definido"
  },
  "en": {
    "stages": "Stages",
    "generalDetails": "General details",
    "tournamentTitle": "Title",
    "thereAreErrors": "There are errors in the form",
    "close": "Close",
    "edit": "Edit",
    "save": "Save",
    "delete": "Remove",
    "date": {
      "label": "Date",
      "placeholder": "Choose a date"
    },
    "startDate": {
      "label": "Start date",
      "min": "Choose a more recent date",
      "required": "Enter a start date"
    },
    "endDate": {
      "label": "End Date",
      "min": "Choose a date after the start date",
      "required": "Enter an end date"
    },
    "title": {
      "label": "Title",
      "pt": "Title in Portuguese",
      "en": "English title",
      "required": "Enter a title in English and Portuguese."
    },
    "description": {
      "label": "Description",
      "pt": "Description in Portuguese",
      "en": "Description in English",
      "required": "Enter a description in English and Portuguese."
    },
    "charsLimit": "Limit of {limit} characters.",
    "notDefined": "Not defined"
  }
}
</i18n>
