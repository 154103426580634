<template>
  <div>
    <DashboardHero
      :title="tournament.title[language] || $t('notDefined')"
      :title-label="tournamentSubtitle"
      class="mt-2 mb-5"
      type="cover"
      background="abstract-02"
    >
      <template #toolbar>
        <div v-if="tournament.is_signup_open" class="px-3 py-1 rounded-full bg-primary bg-opacity-90">
          <div class="inline-flex flex-shrink-0 text-white relative gap-2">
            <div class="font-semibold">Inscrições abertas</div>
            <fw-dot color="white" inline class="mt-1" />
          </div>
        </div>
        <div v-else>
          <div class="text-sm opacity-70 font-medium">Inscrições encerradas</div>
        </div>
      </template>

      <template #footer>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('league') }}</fw-label>
          <div>{{ league?.title?.[language] }}</div>
        </div>
        <div>
          <fw-label color="text-white opacity-50">{{ $t('tournamentStatus.label') }}</fw-label>
          <div v-if="tournament.archived_at">{{ $t('tournamentStatus.archived') }}</div>
          <div v-else>
            {{ tournament.is_visible ? $t('tournamentStatus.published') : $t('tournamentStatus.draft') }}
          </div>
        </div>
        <div v-if="tournament?.created_at">
          <fw-label color="text-white opacity-50">{{ $t('createdAt') }}</fw-label>
          <div>{{ tournament.created_at | formatDate }}</div>
        </div>
        <div v-if="tournament?.updated_at">
          <fw-label color="text-white opacity-50">{{ $t('updatedAt') }}</fw-label>
          <div>{{ tournament.updated_at | formatDate }}</div>
        </div>
      </template>
    </DashboardHero>

    <fw-panel v-if="tournamentStatsTotals.length && tournament.status !== 'draft'" title="Estatísticas" class="my-5">
      <PanelStats :stats="tournamentStatsTotals" :stats-warning="false" />
    </fw-panel>

    <!-- <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ tournament, currentStage }"></json-viewer>
    </fw-panel-info> -->
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import { TOURNAMENT_TYPES } from '@/utils/index.js'

export default {
  components: {
    DashboardHero,
    PanelStats,
  },

  props: {
    tournament: {
      type: Object,
      default: () => {},
    },
    sport: {
      type: Object,
      default: () => {},
    },
    stats: {
      type: Object,
      default: () => {},
    },
    league: {
      type: Object,
      default: () => {},
    },
    // checks: {
    //   type: Object,
    //   default: () => {},
    // },
    users: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tournamentTypes: TOURNAMENT_TYPES,
    }
  },

  computed: {
    tournamentSubtitle() {
      if (!this.sport) return ''
      return `${this.sport?.modality?.title?.[this.language]} ${this.sport?.category?.[this.language]}`
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    secondaryLanguage() {
      return this.language === 'pt' ? 'en' : 'pt'
    },
    tournamentStatsTotals() {
      return []
      // return [
      //   {
      //     label: 'Torneios',
      //     value: 99,
      //     valueSuffix: null,
      //     description: null,
      //   },
      //   {
      //     label: 'Equipas aprovadas',
      //     value: 99,
      //     valueSuffix: null,
      //     description: null,
      //   },
      //   {
      //     label: 'Equipas por aprovar',
      //     value: 99,
      //     valueSuffix: null,
      //     description: null,
      //   },
      //   {
      //     label: 'Equipas inscritas',
      //     value: 99,
      //     valueSuffix: null,
      //     description: null,
      //   },
      //   {
      //     label: 'Participantes',
      //     value: 99,
      //     valueSuffix: null,
      //     description: null,
      //   },
      // ]
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "statusMainBoardTitle": "Estado",
    "tournamentKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "league": "Liga",
    "tournamentStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "label": "Estado",
      "signupOpen": "Inscrições abertas",
      "archived": "Arquivado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "startSignupAt": "Inicio de inscrições",
    "endSignupAt": "Fim de inscrições",
    "startAt": "Início do torneio",
    "endAt": "Fim do torneio",
    "publishedAt": "Publicado em",
    "updatedAt": "Atualizado em",
    "people": "Pessoas",
    "submittedApplications": "Submetidas",
    "candidates": "Candidaturas",
    "yes": "Sim",
    "no": "Não"
  },
  "en": {
    "league": "League",
    "statusMainBoardTitle": "Estado",
    "tournamentKey": "Chave do procedimento",
    "createdAt": "Criado em",
    "creator": "Criador",
    "notDefined": "Não definido",
    "title": "Titulo",
    "tournamentStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "label": "Estado",
      "signupOpen": "Inscrições abertas",
      "archived": "Arquivado"
    },
    "phaseFinished": "Terminado",
    "phaseOngoing": "A decorrer",
    "phasePending": "Pendente",
    "startSignupAt": "Inicio de inscrições",
    "endSignupAt": "Fim de inscrições",
    "startAt": "Início do torneio",
    "endAt": "Fim do torneio",
    "publishedAt": "Publicado em",
    "updatedAt": "Atualizado em",
    "people": "Pessoas",
    "submittedApplications": "Submitted",
    "candidates": "Applications",
    "yes": "Sim",
    "no": "Não"
  }
}
</i18n>
